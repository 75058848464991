<template>
  <div v-if="data" :class="[$style.container, {
    [$style.tiled]: temp === 'tiled',
    [$style.columns]: temp === 'columns'
  }]">
    <div :class="$style.header">
      <div>
        <h3 :class="$style.title">{{ title }}</h3>
        <div :class="$style.line" :style="{background: color}"></div>
      </div>
      <div :class="$style.more">
        <a :href="url" target="_blank" v-if="url">
          查看更多
          <i class="el-icon-arrow-right text-[12px] -ml-1"></i>
        </a>
      </div>
    </div>
    <!-- 多列布局 -->
    <div :class="$style.content" v-if="temp === 'columns'">
      <HomeProductListCol :data="data">
      </HomeProductListCol>
    </div>
    <!-- 平铺 -->
    <div :class="$style.content" v-if="temp === 'tiled'">
      <HomeProductList :items="data">
      </HomeProductList>
    </div>
    <div :class="$style.content" v-if="temp === 'grid'">
      <HomeProductListGrid :items="data"></HomeProductListGrid>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "@nuxtjs/composition-api";
import HomeProductList from './HomeProductList.vue'
import HomeProductListCol from './HomeProductListCol.vue'
import HomeProductListGrid from './HomeProductListGrid.vue'

export default defineComponent({
  name: 'HomeProductSection',
  components: {
    HomeProductList,
    HomeProductListCol,
    HomeProductListGrid
  },
  props: {
    data: {
      /**
       * @typedef {Awaited< ReturnType< import('~/services/common').getHomeData > >['data']} HomeData
       * @typedef {HomeData['yangtze_river']} YangtzeRiver;
       * @typedef {HomeData['caribbean']} Caribbean;
       * @type {import('vue').PropType< Caribbean |  YangtzeRiver>}
       */
      type: Array,
      default: () => [],
    },

    title: String,
    url: String,
    type: String,
    color: String,

    temp: {
      /** @type { 'tiled' | 'columns' | 'grid' } */
      type: String,
      default: ''
    },
    hasLineColor: {
      type: Array,
      default: () => {[]}
    }
  },
  setup() {
    return {}
  }
})
</script>

<style lang="scss" module>
.container {
  border-radius: 20px;
}
.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding-bottom: 20px;
}
.title {
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 2.25rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
}
.content {
  padding-bottom: 8px;
  padding-top: 0px;
}
.ad_link {
  border-radius: 0.25rem;
  display: block;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.ad_img {
  border-radius: 0.25rem;
  display: block;
  height: 100%;
  width: 100%;
}
.line {
  height: 5px;
  width: 100%;
  border-radius: 30px;
  margin-top: 14px;
}
.more {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
  margin-left: 0.125rem;
  padding-bottom: 14px;
  text-align: right;
  align-self: end;
  border-bottom: 2px solid #E5E7EB;
}
.more:hover {
  color: #005EDC;
}
</style>
