<template>
    <div :class="$style.container">
        <div :class="$style.header">
            <h3 :class="$style.title">{{ title }}</h3>
        </div>
        <div :class="[$style.content]">
            <el-row :gutter="20">
                <el-col v-for="(item, index) in items" :key="index" :span="8">
                    <HomeHotRecommendBox :title="item.title" :style="getBoxStyles(index)">
                        <HomeHotRecommendGroup :products="item.content" />
                    </HomeHotRecommendBox>
                    <template v-for="(ad, adIndex) in item.content">
                        <a v-if="ad.type === 'image'" :key="adIndex" class="block mt-5 h-[180px]" :href="ad.url" :target="ad.target">
                            <el-image class="w-full h-full rounded" fit="cover" :src="ad.image" />
                        </a>
                    </template>

                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@nuxtjs/composition-api";
import HomeHotRecommendBox from './HomeHotRecommendBox.vue';
import HomeHotRecommendGroup from './HomeHotRecommendGroup.vue'

import IMG_TITLE_1 from '@/assets/img/home/rec-title-1.png';
import IMG_TITLE_2 from '@/assets/img/home/rec-title-2.png';
import IMG_TITLE_3 from '@/assets/img/home/rec-title-3.png';
function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        : null;
}
export default defineComponent({
    name: 'HomeHotRecommend',
    components: { HomeHotRecommendBox, HomeHotRecommendGroup },
    props: {
        title: String,
        items: {
            /** 
             *
             * @typedef {Awaited< ReturnType< import('~/services/common').getHomeData > >['data']} HomeData
             * @type {import('vue').PropType< Array< import('ts-essentials').ValueOf<Pick<HomeData, 'month_hot' | 'small_luxury' | 'antarctic_explore'>>> >} 
             * */
            type: Array,
            default: () => []
        }
    },
    setup() {

        const cols = [{
            title: '当月热卖',
            len: 5,
            ad: false,
            color: '#DC6F50',
            title_bg: IMG_TITLE_1
        }, {
            title: '小型奢华',
            len: 3,
            ad: true,
            color: '#BA832B',
            title_bg: IMG_TITLE_2
        }, {
            title: '南极探索',
            len: 5,
            ad: false,
            color: '#163590',
            title_bg: IMG_TITLE_3
        }]

        const toRgb = (hex) => {
            let rgb = hexToRgb(hex);

            return rgb ? [rgb.r, rgb.g, rgb.b].join(',') : hex;
        }
        const getBoxStyles = (index) => {
            const item = cols[index];
            const rgb = toRgb(item.color);
            return {
                '--module-color': item.color,
                'background': `linear-gradient(180deg, rgba(${rgb}, .1) 0%, #F8F8F8 100%)`,
                'border-color': `rgba(${rgb} , .2)`,
                '--title-bg-img': `url(${item.title_bg})`
            }
        }

        return {
            getBoxStyles
        }
    }
})
</script>

<style lang="scss" module>
.header {
  padding-bottom: 2rem;
}
.title {
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 2.25rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
}
</style>