<template>
  <div :class="$style.container">
    <!-- <el-carousel indicator-position="none">
      <el-carousel-item v-for="(banner, index) in banners" :key="index"> -->
        <a class="block w-full h-full" :href="banner.url" target="_target">
          <el-image class="w-full h-full" fit="cover" :src="banner.path" />
        </a>
      <!-- </el-carousel-item>
    </el-carousel> -->
  </div>
</template>

<script>
import { defineComponent } from "@nuxtjs/composition-api";
export default defineComponent({
  name: 'HomeBanner',
  props: {
      banner: {
        type: Object,
        default: () => ({})
      },
  },
})
</script>

<style lang="scss" module>
.container {
  height: 200px;
  width: 100%;
}
</style>
