<template>
    <div :class="$style.container">
        <div class="main-width">
            <div :class="$style.header">
                <h3 :class="$style.title">{{title}}</h3>
            </div>

            <div :class="$style.content">
                <swiper ref="swiperComponentRef" :options="swiperOptions">
                    <swiper-slide v-for="item in items" :key="item.id">
                        <nuxt-link class="block" :to="{
                            path: '/harbor/city',
                            query: {
                                id: item.id
                            }
                        }" target="_blank">
                            <VResponsive :aspect-ratio="1 / 1" :class="$style.box">
                                <div class="absolute inset-0 rounded overflow-hidden">
                                    <el-image class="w-full h-full" :class="$style.poster" fit="cover" :src="item.images" />
                                </div>
                                <div :class="$style.mask"></div>
                                <div class="absolute inset-0 flex flex-col justify-center items-center text-white">
                                    <div :class="$style.des_title">{{item.shortname}}</div>
                                    <div :class="$style.des_num">{{ item.product_count }}个产品可选</div>
                                </div>
                            </VResponsive>
                        </nuxt-link>
                    </swiper-slide>
                </swiper>


                <button v-if="!state.isBeginning" :class="$style.swiperBtnPrev" type="button" @click="onPrev">
                    <i class="el-icon-arrow-left" />
                </button>
                <button v-if="!state.isEnd" :class="$style.swiperBtnNext" type="button" @click="onNext">
                    <i class="el-icon-arrow-right" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed, reactive } from "@nuxtjs/composition-api";
import VResponsive from '~/components/VResponsive/VResponsive.vue'

export default defineComponent({
    name: 'HomeHotDestination',
    components: { VResponsive },
    props: {
        title: String,
        /** 
         * @typedef {Awaited< ReturnType< import('~/services/common').getHomeData > >['data']} HomeData
         * @type {import('vue').PropType< HomeData['hot_port'] >} 
         * 
         * */
        items: Array
    },
    setup() {
        const swiperComponentRef = ref();
        const swiperComponentinstance = computed(() => swiperComponentRef.value?.$swiper)
        const swiperOptions = {
            slidesPerView: 4,
            spaceBetween: 24,
            navigation: {
                nextEl: ".j_next",
                prevEl: ".j_prev"
            },
            on: {
                // reachBeginning: () => state.isBeginning = true,
                slideChange() {
                    Object.assign(state, {
                        isBeginning: this.isBeginning,
                        isEnd: this.isEnd
                    })
                },
            }
        };

        const state = reactive({
            isBeginning: true,
            isEnd: false
        })
        const onNext = () => swiperComponentinstance.value?.slideNext();
        const onPrev = () => swiperComponentinstance.value?.slidePrev();


        return {
            swiperComponentRef,
            onNext,
            onPrev,
            state,
            swiperOptions,
        }
    }
})
</script>

<style lang="scss" module>
.container {
  padding-top: 3.75rem;
  padding-bottom: 5rem;
  background: #0846D9  url('~@/assets/img/home/des-bg.png') center center / 1920px auto no-repeat;
}
.header {
  padding-bottom: 2rem;
}
.title {
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 2.25rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.content {
  padding-top: 0px;
  position: relative;
}
.swiperBtnPrev, .swiperBtnNext {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
  height: 3.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: -1.75rem;
  position: absolute;
  top: 50%;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  width: 3.5rem;
  z-index: 10;
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.swiperBtnPrev:hover, .swiperBtnNext:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 94, 220, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
.swiperBtnNext {
  right: -1.75rem;
}
.swiperBtnPrev {
  left: -1.75rem;
}
.des_title {
  font-weight: 500;
  font-size: 2.25rem;
  line-height: 2.5rem;
  text-shadow: 0px 4px 12px rgba(0,0,0,0.302);
}
.des_num {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 1.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.mask {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0,0.1);
}
.poster {
  transition: all 0.3s;
}
.box:hover .poster {
  transform: scale(1.2);
}
</style>