<template>
    <div class="v-radio-list">
        <el-scrollbar :view-style="{
            'max-height': height
        }">
            <!-- <div> -->
            <div v-for="option in options" :key="option.value" class="v-radio-list__item" :class="{
                'v-radio-list__item--active': value === option.value
            }" @click="handlerOptionClick(option.value)">
            <div class="line-clamp-1">{{ option.text }}</div>    
            <button v-if="value === option.value" class="v-radio-list__item-clear" type="button" @click.stop="handlerClear">清除</button>
        </div>
            <!-- </div> -->
        </el-scrollbar>
    </div>
</template>

<script>
import {
    defineComponent,
    nextTick,
    // ref
} from "@nuxtjs/composition-api";
// import useMergedState from "~/composables/useMergedState";
export default defineComponent({
    name: 'RadioList',
    props: {
        height: {
            type: String,
            default: '338px'
        },
        options: {
            type: Array,
            default: () => [
            ]
        },
        value: {
            type: String,
            default: '0'
        }
    },
    emits: ['input', 'change', 'clear'],
    setup(props, {
        emit
    }) {
        const handlerOptionClick = (value) => {
            emit('input', value);
            nextTick(() => {
                emit('change', value)
            })
        }
        const handlerClear = () => {
            emit('clear')
        }
        return {
            handlerClear,
            handlerOptionClick
        }
    }
})
</script>

<style lang="scss">
.v-radio-list {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.v-radio-list__item {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-size: 1rem;
  line-height: 1.5rem;
  line-height: 1.375;
  min-width: 20rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-right: 3.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  position: relative;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
}
.v-radio-list__item--active {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
.v-radio-list__item-clear {
  --tw-border-opacity: 1;
  border-color: rgba(151, 154, 168, var(--tw-border-opacity));
  border-radius: 0.125rem;
  border-width: 1px;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(96, 99, 112, var(--tw-text-opacity));
}
.v-radio-list__item:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
</style>