<template functional>
    <div :class="$style.group_container" v-bind="data">
        <div :class="$style.group_header">
            {{ props.title }}
        </div>

        <slot />
    </div>
</template>

<script>
import { defineComponent } from "@nuxtjs/composition-api";

export default defineComponent({
    name: 'HomeHotRecommendBox',
    props: {
        title: String,
    }
})
</script>

<style lang="scss" module>
.group {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}
.group_container {
  --module-color: #EEE0D9;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-color: var(--module-color);
  border-radius: 0.25rem;
  border-width: 1px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 1.875rem;
}
.group_header {
  font-weight: 500;
  font-size: 1.625rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  text-align: center;
  color: var(--module-color);
  background: var(--title-bg-img) bottom center no-repeat;
  background-size: 345px 54px;
}
</style>