<template>
    <div class="grid grid-cols-5 gap-x-16px gap-y-20px" :class="[{'grid-rows-2': gridRowsNum}]">
      <template v-for="item in items">
        <div :class="[{ 'col-span-2': item.col_span === 2, 'col-span-3': item.col_span === 3,'row-span-2' : item.row_span === 2, 'row-span-3': item.row_span === 3,
          'col-start-5': isColStart5(item.type, item.row_span), 'row-start-1': isRowStart1(item.type, item.row_span),
          [$style.product_hover]: isProductHover(item.type, item.row_span), [$style.product_shadow]: item.type === 'product'}, $style.product]" :key="item.product_no">
          <div v-if="item.type === 'image'" :class="$style.ad" :style="getAdStyle(item)">
            <a :href="item.url" target="_blank" :class="$style.ad_link">
              <el-image :class="$style.ad_img" fit="cover" :src="item.image" />
            </a>
          </div>
          <nuxt-link v-else :class="$style.product_link" :to="buildProductDetailLink({ ...item, product_type: '1' })" target="_blank">
            <!-- 图片为背景图 -->
            <div :class="$style.product_wrap"  v-if="item.layout === 'above'">
              <el-image :class="[$style.product_img,$style.bg_img]" fit="cover" lazy :src="item.image" />
              <div :class="[$style.product_content, $style.product_above]">
                <div :class="$style.product_above_title"><ProductLevelIcon v-if="item.level_pic" :icon="item.level_pic" />{{ item.name }}</div>
                <div :class="[$style.product_price, $style.product_price_above]">
                  <span :class="$style.product_price_pre">{{ item.currency_label }}</span>
                  <span :class="$style.product_price_num">{{ item.min_sale_price }}</span>
                  <span :class="[$style.product_price_qi, $style.product_price_above_qi]">起/人</span>
                </div>
              </div>
            </div>
            <template v-if="item.layout === 'none' || item.layout === '' || item.layout === 'below'">
              <div :class="$style.product_cover">
                <el-image :class="$style.product_img" fit="cover" lazy :src="item.image" />
              </div>
              <div :class="$style.product_content">
                <div :class="$style.product_title"><ProductLevelIcon v-if="item.level_pic" :icon="item.level_pic" />{{ item.name }}</div>
                <div :class="$style.product_price">
                  <span :class="$style.product_price_pre">{{ item.currency_label }}</span>
                  <span :class="$style.product_price_num">{{ item.min_sale_price }}</span>
                  <span :class="$style.product_price_qi">起/人</span>
                </div>
              </div>
            </template>
          </nuxt-link>
        </div>
      </template>
    </div>
</template>

<script>
import { defineComponent, useStore, ref, computed } from "@nuxtjs/composition-api";
import VResponsive from '~/components/VResponsive/VResponsive.vue'
import { buildProductDetailLink } from '~/utils/product'
import ProductLevelIcon from "~/components/ProductLevelIcon.vue";

export default defineComponent({
    name: 'ProductList',
    components: { VResponsive, ProductLevelIcon },
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const store = useStore();
        const isDistributor = computed(() => store.getters.isDistributor)
        const gridRowsNum = computed(() => {
          return props.items.find(item => {
            if (item.row_span > 1 || item.col_span > 1) {
              return true
            }else return false
          })
        })
        const getAdStyle = (item) => {
          return {
            height: `${282 * item.row_span + 20 * (item.row_span - 1)}px`,
          };
        };
        const isColStart5 = (type, row_span) => {
          return type === 'image' && row_span > 1 && props.items.length > 1 ? true : false
        }
        const isRowStart1 = (type, row_span) => {
          return type === 'image' && row_span > 1 && props.items.length > 1 ? true : false
        }
        const isProductHover = (type, row_span) => {
          return type === 'product' ? true : false
        }
        return {
            buildProductDetailLink,
            isDistributor,
            gridRowsNum,
            getAdStyle,
            isRowStart1,
            isColStart5,
            isProductHover
        }
    }
})
</script>

<style lang="scss" module>
.wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.product {
  border-radius: 0.25rem;
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}
.product_shadow {
  position: relative;
}
.product_shadow::before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 120px;
  left: -1px;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
}
.product_shadow::after {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 120px;
  right: -1px;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
}
.product:hover .product_img {
  transition: all 0.3s;
  transform: scale(1.2);
}
.product:hover .product_title {
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
.product_link {
  display: block;
  height: 100%;
}
.product_cover {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  height: 160px;
  overflow: hidden;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
.product_img {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: block;
  height: 100%;
  width: 100%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 1000ms;
  -o-transition-duration: 1000ms;
  transition-duration: 1000ms;
}
.product_content {
  padding: 1rem;
  padding-bottom: 13px;
}
.product_title {
  font-weight: 500;
  height: 3rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.product_above_title {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  max-height: 3.25rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.product_price {
  margin-top: 0.25rem;
  text-align: right;
  --tw-text-opacity: 1;
  color: rgba(204, 75, 12, var(--tw-text-opacity));
}
.product_price_pre {
  font-size: 1rem;
  line-height: 1.5rem;
}
.product_price_num {
  font-weight: 500;
  font-size: 1.625rem;
}
.product_price_qi {
  --tw-text-opacity: 1;
  color: rgba(96, 99, 112, var(--tw-text-opacity));
}
.product_price_above {
  text-align: left;
}
.product_price_above_qi {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.product_wrap {
  border-radius: 0.25rem;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
}
.product_above {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: linear-gradient(360deg, #000000 0%, rgba(0,0,0,0) 100%);
}
.product_hover {
  border: 1px solid transparent;
}
.product_hover:hover {
  border: 1px solid #005EDC;
}
.ad {
  height: 100%;
  width: 100%;
}
.ad_img {
  border-radius: 0.25rem;
  height: 100%;
  width: 100%;
}
.ad_link {
  display: block;
  height: 100%;
  width: 100%;
}
.bg_img {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
</style>
