<template>
    <div :class="$style.search" class="j_search_wrap">
        <el-form :class="$style.search_form">
            <!-- 邮轮航线 -->
            <el-form-item>
                <TreeSelectPicker :value="formData.route" :main-active-id="formData.cruise_route_first"
                    portal=".j_search_wrap" :panel-class="$style.search_content" placeholder="邮轮航线"
                    field-icon="ficon ficon-ziyuancaigou_mudedi" :items="routes"
                    @change="onRouteChange" />
            </el-form-item>
            <!-- 邮轮品牌 -->
            <el-form-item>
                <TreeSelectPicker :value="formData.cruise" :main-active-id="formData.brand" portal=".j_search_wrap"
                    :panel-class="$style.search_content" placeholder="邮轮品牌" field-icon="ficon ficon-a-ziyuancaigou_pinpai"
                    :items="brands" @change="onCruiseChange" />
            </el-form-item>
            <!-- 出发城市 -->
            <el-form-item>
                <TreeSelectPicker :value="formData.city" :main-active-id="formData.country" portal=".j_search_wrap"
                    :panel-class="$style.search_content" field-icon="ficon ficon-ziyuancaigou_gangkou" placeholder="出发城市"
                    :items="cities"
                    @change="onCityChange" />
            </el-form-item>
            <!-- 出游日期 -->
            <el-form-item>
                <TreeSelectPicker :value="formData.travel_date" :main-active-id="formData.year" portal=".j_search_wrap"
                    :panel-class="$style.search_content" placeholder="出游日期" field-icon="ficon ficon-ziyuancaigou_chufariqi"
                    :items="months" @change="onDateChange" />
            </el-form-item>
            <!--出游天数 -->
            <el-form-item>
                <RadioListPicker portal=".j_search_wrap" :panel-class="$style.search_content" v-model="formData.days"
                    field-icon="ficon ficon-ziyuancaigou_chuyoutianshu" placeholder="出游天数" :options="days"
                    @change="onDayChange" />
            </el-form-item>
            <el-button class="w-full block rounded" type="primary" @click="onSubmit">搜索</el-button>
        </el-form>
    </div>
</template>

<script>
import {
    defineComponent,
    useRouter,
    toRaw,
  onMounted
} from "@nuxtjs/composition-api";
import { useCruiseFilterStore } from "~/composables/useCruiseFilter";


// import TreeSelect from '~/components/VTreeSelect/TreeSelect.vue'
// import VRangeCalendar from '~/components/VRangeCalendar/index.vue';
import TreeSelectPicker from '~/components/VTreeSelect/TreeSelectPicker.vue';
import RadioListPicker from '~/components/VRadioList/RadioListPicker.vue'



export default defineComponent({
    name: 'HomeSearchForm',
    components: { TreeSelectPicker, RadioListPicker },
    setup() {

        // const {} = useCruiseFilter();
        // const formData = reactive({
        //     route: "",
        //     cruise_route_first: '',
        //     cruise: "",
        //     brand: '',

        //     days: "",
        //     travel_date: '',
        //     year: '',
        //     departure_city: ''

        // });

        const emptyOrValue = (value) => value === '-1' ? '' : value;
        const {
            state: formData,

            routes,
            months,
            brands,
            days,
            cities,

            fetchFilterData,
            onChange: onFilterChange,

            onRouteChange,
            onCruiseChange,
            onCityChange,
            onDateChange,
            onDayChange
        } = useCruiseFilterStore();

        const router = useRouter();
        function onSubmit() {
            let {
                days,
                travel_date,
                route = '',
                cruise = '',
                city = '',
                ...query
            } = formData
            const { href } = router.resolve({
                path: "/product/automation/foreign-cruise-search",
                query: {
                    ...query,
                    day: days,
                    // month: travel_date,
                    route: emptyOrValue(route) ,
                    cruise: emptyOrValue(cruise),
                    city: emptyOrValue(city),
                    month: emptyOrValue(travel_date)
                }
            });

            window.open(href, "_blank");
        }

        // input

        // const pickerOptions = reactive({
        //     disabledDate(time) {
        //         return time.getTime() <= Date.now();
        //     }
        // });
        return {
            // treeSelectMainIds,

            // pickerOptions,

            formData,
            onFilterChange,

            onRouteChange,
            onCruiseChange,
            onCityChange,
            onDateChange,
            onDayChange,

            routes,
            months,
            brands,
            days,
            cities,

            onSubmit,

        };
    }
})
</script>

<style lang="scss" module>
.search {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.9;
  border-radius: 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.search:hover {
  --tw-bg-opacity: 1;
}
.search_form {
  width: 20rem;
}
.search_content {
  border-radius: 0.75rem;
  border-width: 1px;
  margin-left: 1.25rem;
  overflow: hidden;
  width: 20.5rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
.search :global(.v-field-content) {
  padding-left: 8px;
  padding-right: 8px;
}
</style>
