<template>
    <ul :class="$style.group">
        <li v-for="(product, index) in availableProducts" :key="product.product_no" :class="$style.product">
            <nuxt-link :class="$style.product_link" :to="buildProductDetailLink({...product, product_type: '1'})">
                <div :class="$style.product_cover">
                    <VResponsive :aspect-ratio="100 / 80">
                        <el-image :class="$style.product_img" fit="cover" lazy :src="product.image" />
                    </VResponsive>
                    <img v-if="index === 0" :class="$style.product_serial" src="~@/assets/img/home/rec-serial-1.png" />
                    <img v-if="index === 1" :class="$style.product_serial" src="~@/assets/img/home/rec-serial-2.png" />
                    <img v-if="index === 2" :class="$style.product_serial" src="~@/assets/img/home/rec-serial-3.png" />
                </div>
                <div :class="$style.product_content">
                    <div :class="$style.product_title">
                        <ProductLevelIcon v-if="product.level_pic" class="inline-block" :icon="product.level_pic"  />{{ product.name }}</div>
                    <div :class="$style.product_price">
                        <span :class="$style.product_price_prefix">{{ product.currency_label }}</span><span
                            :class="$style.product_price_num">{{ product.min_sale_price }}</span><span
                            :class="$style.product_price_unit">起/人</span>
                    </div>
                </div>
            </nuxt-link>
        </li>
    </ul>
</template>

<script>
import { defineComponent, computed } from "@nuxtjs/composition-api";
import VResponsive from '~/components/VResponsive/VResponsive.vue'
import ProductLevelIcon from "~/components/ProductLevelIcon.vue";

import { buildProductDetailLink } from "~/utils/product";
export default defineComponent({
    name: 'HomeHotRecommendGroup',
    components: { VResponsive, ProductLevelIcon },
    props: {
        /**
         * @typedef {Awaited< ReturnType< import('~/services/common').getHomeData > >['data']} HomeData
         * @type {import('vue').PropType<HomeData['month_hot']['product_list']>}
         */
        products: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        
        return {
            buildProductDetailLink,
            
            availableProducts: computed(() => props.products.filter((item) => item.type === 'product'))
        }
    }
})
</script>

<style lang="scss" module>
.group {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}
.product {
  margin-bottom: 1.25rem;
  position: relative;
}
.product:last-child {
  margin-bottom: 0px;
}
.product_link {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
}
.product_cover {
  height: 80px;
  margin-right: 0.75rem;
  position: relative;
  width: 100px;
}
.product_serial {
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: 0.25rem;
  top: -0.25rem;
  width: 1.25rem;
}
.product_img {
  border-radius: 0.25rem;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
.product_content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.product_title {
  font-weight: 500;
  height: 2.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.product:hover .product_title {
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
.product_price {
  margin-top: 0.5rem;
  text-align: right;
}
.product_price_prefix, .product_price_num {
  --tw-text-opacity: 1;
  color: rgba(204, 75, 12, var(--tw-text-opacity));
}
.product_price_prefix {
  font-size: 1rem;
  line-height: 1.5rem;
}
.product_price_num {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.product_price_unit {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-left: 0.25rem;
  --tw-text-opacity: 1;
  color: rgba(96, 99, 112, var(--tw-text-opacity));
}
</style>