<template>
  <div :class="$style.wrap">
    <div :class="$style.list" class="clearfix">
      <div v-for="item in groupData.product" :key="item.product_no" :class="$style.product">
        <a :class="$style.product_link" target="_blank" :href="buildProductDetailLink({ ...item, product_type: '1' })">
          <div :class="$style.product_cover">
            <el-image :class="$style.product_img" fit="cover" class="object-cover" lazy :src="item.image" />
          </div>
          <div :class="$style.product_content">
            <div :class="$style.product_name"><ProductLevelIcon v-if="item.level_pic" /> {{ item.name }}</div>
            <div :class="$style.product_price">
              <span :class="$style.product_price_curr">{{ item.currency_label }}</span>
              <span :class="$style.product_price_num">{{ item.min_sale_price }}</span>
              <span :class="$style.product_price_unit">起/人</span>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div :class="$style.ad">
      <a v-for="item in groupData.image" :class="$style.ad_link" :href="item.url" target="_blank">
        <el-image :class="$style.ad_img" fit="cover" :src="item.image" />
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent, useStore, computed } from "@nuxtjs/composition-api";
import VResponsive from '~/components/VResponsive/VResponsive.vue'
import { buildProductDetailLink } from '~/utils/product'

import { groupBy } from 'lodash'
import ProductLevelIcon from "~/components/ProductLevelIcon.vue";

export default defineComponent({
  name: 'HomeProductListCol',
  components: { VResponsive, ProductLevelIcon },
  props: {
    /**
     * @typedef {Awaited< ReturnType< import('~/services/common').getHomeData > >['data']} HomeData
     * @type {import('vue').PropType<HomeData["caribbean"]['product_list']>}
     */
    data: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const store = useStore();
    const isDistributor = computed(() => store.getters.isDistributor)

    const groupData = computed(() => {
      // console.log(props.data, groupBy(props.data, 'type'))
      return Object.assign({
        image: [],
        product: []
      }, groupBy(props.data, 'type'))
    })
    return {
      isDistributor,
      buildProductDetailLink,
      groupData
    }
  }
})
</script>

<style lang="scss" module>
.wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.list {
  display: -ms-grid;
  display: grid;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
  -webkit-row-gap: 1.25rem;
  -moz-row-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  grid-column-gap: 16px;
  column-gap: 16px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.product {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  position: relative;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}
.product::before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: -1px;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
}
.product_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.product:hover {
  border: 1px solid #005EDC;
}
.product:hover .product_img {
  transform: scale(1.2);
}
.product:hover .product_name {
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
.product_cover {
  height: 160px;
  width: 180px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow: hidden;
}
.product_img {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  display: block;
  height: 100%;
  width: 100%;
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
}
.product_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
  padding: 1.25rem;
  padding-bottom: 1rem;
}
.product_name {
  font-weight: 500;
  height: 3rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.product_price {
  margin-top: auto;
  text-align: right;
}
.product_price_curr, .product_price_num {
  --tw-text-opacity: 1;
  color: rgba(204, 75, 12, var(--tw-text-opacity));
}
.product_price_num {
  font-weight: 500;
  font-size: 1.625rem;
}
.ad {
  height: 340px;
  margin-left: 1rem;
  width: 228px;
}
.ad_img {
  border-radius: 0.25rem;
  height: 100%;
  width: 100%;
}
.ad_link {
  display: block;
  height: 100%;
  width: 100%;
}
</style>
