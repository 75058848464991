<template>
  <div class="v-responsive" :style="dimensionStyles">
    <div v-if="aspectStyle" class="v-responsive__sizer" :style="aspectStyle"></div>
    <div class="v-responsive__content" :class="contentClass">
      <slot />
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { isFinite, isNaN } from 'lodash';


function convertToUnit(str, unit = 'px') {
  if (str == null || str === '') {
    return undefined
  } else if (isNaN(+str)) {
    return String(str)
  } else if (!isFinite(+str)) {
    return undefined
  } else {
    return `${Number(str)}${unit}`
  }
}
export default defineComponent({
  name: "VResponsive",
  props: {
    aspectRatio: [String, Number],
    contentClass: String,
    height: [Number, String],
    maxHeight: [Number, String],
    maxWidth: [Number, String],
    minHeight: [Number, String],
    minWidth: [Number, String],
    width: [Number, String],
  },
  computed: {
    computedAspectRatio() {
      return Number(this.aspectRatio);
    },
    aspectStyle() {
      return this.computedAspectRatio
        ? {
          paddingBottom: `${(1 / this.computedAspectRatio) * 100}%`
        }
        : undefined;
    },
    dimensionStyles() {
      return {
        height: convertToUnit(this.height),
        maxHeight: convertToUnit(this.maxHeight),
        maxWidth: convertToUnit(this.maxWidth),
        minHeight: convertToUnit(this.minHeight),
        minWidth: convertToUnit(this.minWidth),
        width: convertToUnit(this.width),
      }
    }
  }
});
</script>
<style lang="scss">
.v-responsive {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  flex: 1 0 auto;
}
.v-responsive__content {
  flex: 1 0 0px;
  max-width: 100%;
}
.v-responsive__sizer~.v-responsive__content {
  margin-left: -100%;
}
.v-responsive__sizer {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  flex: 1 0 0px;
}
</style>
