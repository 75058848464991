<template>
  <div class="wrap fixed z-99 right-20px bottom-20px cursor-pointer" :class="className" ref="imageElement">
    <el-image class="qrcode" v-if="isShow" fit="contain" @click="onShow" src="~@/assets/img/global/home/qr-code.png"/>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from "@nuxtjs/composition-api";
import { useGQrcodeStore } from "~/composables/useGQrcode"

export default defineComponent({
    name: 'GQrcode',
    setup() {
      const { isShow, onShow, className, elementTop } = useGQrcodeStore()
      const translateYValue = ref('')
      const imageElement = ref(null)
      const imageTop = ref('')

      watch(() => elementTop.value, (val) => {
        if(val) {
          translateYValue.value = val
          document.documentElement.style.setProperty('--translate-y', -(imageTop.value - val + 30) + 'px')
        }
      }, {immediate: true, deep: true})

      onMounted(() => {
        document.documentElement.style.setProperty('--translate-y', translateYValue.value);
        if(imageElement.value) {
          const rect = imageElement.value.getBoundingClientRect()
          imageTop.value = rect.top
        }
      });

      return { isShow, onShow, className, translateYValue, imageElement }
    }
})
</script>

<style lang="scss" scoped>

.qrcode {
  height: 98px;
  width: 390px;
}

.slide-fade {
  animation: bounce-in 0.5s forwards;
}

@keyframes bounce-in {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(200px) translateY(var(--translate-y)) scale(0.1);
    opacity: 0.1;
  }
}

</style>
