<template>
  <div v-if="!isLoading" :class="$style.container">
    <div class="relative mb-12">
      <!-- 这里的定位用来限制层级 -->
      <div class="relative z-1">
        <HomeBanner :height="bannerHeight" :banners="state.banner" />
      </div>
      <div
        class="absolute z-2 left-1/2 -ml-150 top-1/2 transform -translate-y-1/2"
      >
        <HomeSearchForm />
      </div>
    </div>

    <template v-for="(item, index) in coms">
      <!-- 邮轮产品 -->
      <div v-if="item.type === '1'" :key="item.type" :class="$style.section">
        <div class="main-width">
          <HomeProductSection
            :color="item.color"
            temp="tiled"
            :type="item.type"
            :title="item.title"
            :url="item.url"
            :data="item.data"
          />
        </div>
      </div>

      <!-- 长江游轮 -->
      <div v-if="item.type === '6'" :key="item.type" :class="$style.section">
        <div class="main-width">
          <HomeProductSection
            :color="item.color"
            temp="grid"
            :type="item.type"
            :title="item.title"
            :url="item.url"
            :data="item.data"
          />
        </div>
      </div>

      <!-- 中国母港 -->
      <div v-if="item.type === '7'" :key="item.type" :class="$style.section">
        <div class="main-width">
          <HomeProductSection
            :color="item.color"
            temp="grid"
            :type="item.type"
            :title="item.title"
            :url="item.url"
            :data="item.data"
          />
        </div>
      </div>

      <!-- 海外邮轮 -->
      <div v-if="item.type === '8'" :key="item.type" :class="$style.section">
        <div class="main-width">
          <HomeProductSection
            :color="item.color"
            temp="grid"
            :type="item.type"
            :title="item.title"
            :url="item.url"
            :data="item.data"
          />
        </div>
      </div>

      <!-- banner -->
      <div
        v-if="item.type === '9'"
        :key="item.type"
        :class="[$style.section, $style.banner]"
      >
        <div class="main-width">
          <HomeCenterBanner :banner="item.data" />
        </div>
      </div>

      <!-- 加勒比海 -->
      <div v-if="item.type === '2'" :key="item.type" :class="$style.section">
        <div class="main-width">
          <HomeProductSection
            :color="item.color"
            temp="columns"
            :type="item.type"
            :title="item.title"
            :url="item.url"
            :data="item.data"
          />
        </div>
      </div>

      <!-- 热门推荐  -->
      <div v-if="item.type === '3'" :key="item.type" :class="$style.section">
        <div class="main-width">
          <HomeHotRecommend :items="item.data" :title="item.title" />
        </div>
      </div>

      <!-- 邮轮公司 -->
      <HomeHotDestination
        v-if="item.type === '4'"
        :key="item.type"
        :class="[$style.section]"
        class="!mt-15"
        :items="item.data"
        :title="item.title"
      />

      <!-- 热门推荐  -->
      <div
        v-if="item.type === '5'"
        :key="item.type"
        :class="[$style.section]"
        class="!mt-15"
      >
        <div class="main-width">
          <HomeCompany :items="item.data" :title="item.title" />
        </div>
      </div>
    </template>
    <!-- 合作伙伴 -->
    <Partners v-if="state.partner.length" :partners="state.partner" />
    <AppDock />
    <GQrcode />
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  useContext,
  useFetch,
  watchEffect,
} from "@nuxtjs/composition-api";
import HomeBanner from "@/views/home/HomeBanner.vue";
import HomeProductSection from "@/views/home/HomeProductSection.vue";
import HomeHotRecommend from "~/views/home/HomeHotRecommend.vue";
import HomeHotDestination from "~/views/home/HomeHotDestination.vue";
import HomeCompany from "~/views/home/HomeCompany.vue";
import HomeSearchForm from "~/views/home/HomeSearchForm.vue";
import AppDock from "~/components/layouts/AppDock.vue";
import HomeCenterBanner from "@/views/home/HomeCenterBanner.vue";
import Partners from "@/views/home/Partners.vue";
import GQrcode from "~/components/GQrcode.vue";

import {
  useAsyncState,
  useWindowScroll,
  useWindowSize,
} from "@vueuse/core";

import { getHomeData } from "~/services/common";
import { ERROR_OK } from "~/constants/const-http";
import { isEmpty } from "lodash";

import { useCruiseFilterProvide } from "~/composables/useCruiseFilter";

const MAP_LINE_COLOR = [
  "linear-gradient(90deg, #005EDC 0%, #FFFFFF 100%)",
  "linear-gradient(90deg, #F25B3B 0%, #FFFFFF 100%)",
  "linear-gradient(90deg, #1F9D8B 0%, #FFFFFF 100%)",
  "linear-gradient(90deg, #FFB200 0%, #FFFFFF 100%)",
  "linear-gradient(90deg, #F2007D 0%, #FFFFFF 100%)",
];

export default defineComponent({
  name: "IndexPage",
  layout: "Main",
  components: {
    HomeBanner,
    HomeSearchForm,
    HomeProductSection,
    HomeHotRecommend,
    HomeHotDestination,
    HomeCompany,
    AppDock,
    HomeCenterBanner,
    Partners,
    GQrcode,
  },

  head: {
    title: "首页",
  },
  setup() {
    const { state, execute, isLoading } = useAsyncState(
      async () => {
        const { code, data, msg } = await getHomeData();
        if (code !== ERROR_OK) {
          throw new Error(msg);
        }
        return data;
      },
      {
        content: [],
        banner: [],
        partner: [],
      },
      {
        immediate: false,
        onError(error) {
          console.error(error);
        },
      }
    );

    useFetch(async () => {
      try {
        await execute(0);
      } catch (error) {
        console.error(error);
      }
    });

    const recommends = computed(() =>
      [
        state.value.month_hot,
        state.value.small_luxury,
        state.value.antarctic_explore,
      ].filter((value) => !isEmpty(value))
    );
    const { store } = useContext();
    const isLoggedIn = computed(() => store.getters.loggedIn);

    const coms = computed(() =>{
      let colorIndex = 0;
      return state.value.content.map((item, index) => {
        if(index !== 0 && !['3', '4', '5', '9'].includes(item.type)) {
          colorIndex === MAP_LINE_COLOR.length - 1 ? colorIndex = 0 : colorIndex++
        }
        return {
          ...item,
          color: MAP_LINE_COLOR[colorIndex > MAP_LINE_COLOR.length - 1 ? 0 : colorIndex],
        };
      })
    }

    );
    useCruiseFilterProvide(undefined, {
      immediate: true,
    });

    const { width: winWidth } = useWindowSize({
      initialWidth: 1920,
    });
    const bannerHeight = computed(() => {
      const scaleX = (winWidth.value > 0 ? winWidth.value : 1920) / 1920;
      return Math.max(scaleX * 520, 520);
    });
    const { y: scrollTop } = useWindowScroll();

    return {
      scrollTop,
      bannerHeight,

      isLoading,
      state,
      recommends,

      isLoggedIn,

      coms,

    };
  },
});
</script>

<style lang="scss" module>
.container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-bottom: 5rem;
}
.section + .section {
  margin-top: 2.5rem;
}
.banner {
  margin-bottom: 3rem;
}
</style>
