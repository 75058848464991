<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <h3 :class="$style.title">{{title}}</h3>
    </div>
    <div class="relative -mt-2.5">
      <swiper ref="swiperComponentRef" :class="$style.companys" :options="swiperOptions">
        <swiper-slide :class="$style.company" v-for="(company, index) in items" :key="company.id">
          <nuxt-link :class="$style.company_link" :style="getItemStyles(index)" :to="`/company/${company.id}`"
            target="_blank">
            <VResponsive :aspect-ratio="240 / 160">
              <div :class="$style.company_cover">
                <el-image :class="$style.company_img" fit="cover" :src="company.image" />
              </div>
            </VResponsive>
            <el-image :class="$style.company_logo" fit="cover" :src="company.logo" />
            <h3 :class="$style.company_name">{{ company.name }}</h3>
            <div :class="$style.company_route">{{ company.route_count }}个目的地航线</div>
            <div :class="$style.company_date">{{ company.date_count }}个出发日期可选</div>
          </nuxt-link>
        </swiper-slide>
      </swiper>
      <button v-if="!state.isBeginning" :class="$style.swiperBtnPrev" type="button" @click="onPrev">
        <i class="el-icon-arrow-left" />
      </button>
      <button v-if="!state.isEnd" :class="$style.swiperBtnNext" type="button" @click="onNext">
        <i class="el-icon-arrow-right" />
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, reactive } from "@nuxtjs/composition-api";
import VResponsive from '~/components/VResponsive/VResponsive.vue'

function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
}

function toRgb(hex) {
  let rgb = hexToRgb(hex);

  return rgb ? [rgb.r, rgb.g, rgb.b].join(',') : hex;
}
export default defineComponent({
  name: 'HomeCompany',
  components: { VResponsive },
  props: {
    title: String,
    /** 
     * @typedef {Awaited< ReturnType< import('~/services/common').getHomeData > >['data']} HomeData
     * @type {import('vue').PropType< HomeData['company'] >} 
     * 
     * */
    items: Array
  },
  setup() {
    const colors = [
      "#F4E5B8",
      "#F0DED4",
      "#D1EBE0",
      "#F8F1EE",
      "#EDFAF6",
      "#F7F4D4",
      "#F0DAC3",
      "#F9E6E6"
    ];

    function getItemStyles(index) {
      let color = colors[index % colors.length]
      return {
        background: `linear-gradient(180deg, rgba(${toRgb(color)}, .8) 0%, #F8F8F8 100%)`,
        // border: `1px solid rgba(${toRgb(color)}, .6)`
      };
    }
    const swiperComponentRef = ref();
    const swiperComponentinstance = computed(() => swiperComponentRef.value?.$swiper)
    const swiperOptions = {
      slidesPerView: 4,
      spaceBetween: 0,
      navigation: {
        nextEl: ".j_next",
        prevEl: ".j_prev"
      },
      on: {
        // reachBeginning: () => state.isBeginning = true,
        slideChange() {
          Object.assign(state, {
            isBeginning: this.isBeginning,
            isEnd: this.isEnd
          })
        },
      }
    };

    const state = reactive({
      isBeginning: true,
      isEnd: false
    })
    const onNext = () => swiperComponentinstance.value?.slideNext();
    const onPrev = () => swiperComponentinstance.value?.slidePrev();

    return {
      swiperComponentRef,
      onNext,
      onPrev,
      state,
      swiperOptions,
      getItemStyles,
    }
  }
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  width: 73.75rem;
}
.header {
  padding-bottom: 2rem;
}
.title {
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 2.25rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
}
.companys {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  width: 73.75rem;
}
.companys :global(.swiper-button-disabled) {
  display: none;
}
.company {
  padding: 0.625rem;
}
.company_link {
  border-radius: 0.25rem;
  border-width: 1px;
  display: block;
  height: 375px;
  padding: 1.25rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}
.company_cover {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.company_img {
  border-radius: 0.25rem;
  display: block;
  height: 100%;
  width: 100%;
}
.company_logo {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(151, 154, 168, var(--tw-border-opacity));
  border-radius: 0.25rem;
  border-width: 1px;
  display: block;
  height: 2.75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 128px;
}
.company_name {
  font-weight: 500;
  font-size: 1.625rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.company_route, .company_date {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(96, 99, 112, var(--tw-text-opacity));
}
.company_price {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-radius: 0.25rem;
  border-width: 1px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
}
.company:hover .company_link {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  border-color: #ACAEBC;
}
.swiperBtnPrev, .swiperBtnNext {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
  height: 3.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: -1.75rem;
  position: absolute;
  top: 50%;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  width: 3.5rem;
  z-index: 10;
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.swiperBtnPrev:hover, .swiperBtnNext:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 94, 220, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
.swiperBtnNext {
  right: -0.125rem;
}
.swiperBtnPrev {
  left: -1.75rem;
}
</style>