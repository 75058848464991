<template>
  <ul :class="$style.wrap">
      <template v-for="item in items">
          <li v-if="item.type === 'image'" :class="$style.ad">
              <a  :href="item.url" target="_blank" :class="$style.ad_link">
                  <el-image :class="$style.ad_img" fit="cover" :src="item.image" />
              </a>
          </li>
          <li v-else :class="$style.product" :key="item.product_no">
              <nuxt-link :class="$style.product_link" :to="buildProductDetailLink({ ...item, product_type: '1' })"
                  target="_blank">
                  <VResponsive :aspectRatio="307 / 180">
                      <div :class="$style.product_cover">
                          <el-image :class="$style.product_img" fit="cover" lazy :src="item.image" />
                      </div>
                  </VResponsive>
                  <div :class="$style.product_content">
                      <div :class="$style.product_title">{{ item.name }}</div>
                      <div :class="$style.product_price">
                          <span :class="$style.product_price_pre">{{ item.currency_label }}</span>
                          <span :class="$style.product_price_num">{{ item.min_sale_price }}</span>
                          <span :class="$style.product_price_qi">起/人</span>
                      </div>
                  </div>
              </nuxt-link>
          </li>


      </template>
  </ul>
</template>

<script>
import { defineComponent, useStore, computed } from "@nuxtjs/composition-api";
import VResponsive from '~/components/VResponsive/VResponsive.vue'
import { buildProductDetailLink } from '~/utils/product'
export default defineComponent({
  name: 'ProductList',
  components: { VResponsive },
  props: {
      items: {
          type: Array,
          default: () => []
      }
  },
  setup() {
      const store = useStore();
      const isDistributor = computed(() => store.getters.isDistributor)
      return {
          buildProductDetailLink,
          isDistributor
      }
  }
})
</script>

<style lang="scss" module>
.wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
</style>
