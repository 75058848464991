<template>
    <div ref="pickerRef" class="v-radio-list-picker" @click="open">
        <VField class="v-radio-list-picker-field" :icon="fieldIcon" :class="[{
            'v-radio-list-picker-field-active': isOpen
        }]" :placeholder="placeholder" :value="modelValueText" :arrowIcon="arrowIcon" clearable @clear="handlerClear" />

        <client-only>
            <Portal v-if="portal" :selector="portal">
                <div v-if="isOpen" ref="panelRef" :class="panelClass">
                    <RadioList v-model="activeId" :options="options" @change="onChange" @clear="handlerClear" />
                </div>
            </Portal>
            <Transition v-else name="el-zoom-in-top">
                <div ref="panelRef" v-if="isOpen" class="v-radio-list-picker-panel">
                    <RadioList v-model="activeId" :options="options" @change="onChange" @clear="handlerClear" />
                </div>
            </Transition>
        </client-only>
    </div>
</template>

<script>
import {
    defineComponent,
    ref,
    unref,
    computed,
    nextTick
} from "@nuxtjs/composition-api";
import { onClickOutside, useEventListener, useVModel } from "@vueuse/core";
import VField from '~/components/VField/index.vue';
import RadioList from './RadioList.vue';

import { Portal } from '@linusborg/vue-simple-portal';

export default defineComponent({
    name: 'RadioListPicker',
    components: { Portal, VField, RadioList },

    props: {
        placeholder: String,
        fieldIcon: String,
        value: [String, Number],
        options: {
            type: Array,
            default: () => []
        },
        panelClass: String,
        portal: String,
        arrowIcon: {
            type: String,
            default: 'el-icon-arrow-right'
        }
    },
    emits: ['input', 'change'],
    setup(props, {
        emit
    }) {
        const activeId = useVModel(props, 'value', emit, {
            eventName: 'input'
        });
        const isClient = process.client;

        const pickerRef = ref();
        const panelRef = ref();


        const modelValueText = computed(() => {
            const item = props.options.find(v => v.value === activeId.value);
            return item ? item.text : '';
        })

        const isOpen = ref(false);
        const open = () => {
            isOpen.value = true;
        }

        const listener = event => {
            const pickerEle = unref(pickerRef);
            const panelEle = unref(panelRef);

            if (pickerEle === event.target || (pickerEle && pickerEle.contains(event.target)) || (panelEle && panelEle.contains(event.target))) {
                return
            }

            handlerClickOutside();
        }
        useEventListener(isClient ? window : undefined, 'click', listener);

        const handlerClickOutside = () => {
            isOpen.value = false;
        }



        function onChange(value) {
            isOpen.value = false;

            emit('input', value);
            nextTick(() => {
                emit('change', value);
            })
        }

        const handlerClear = () => {
            emit('input', '');
            nextTick(() => {
                emit('change', '');
                emit('clear');
            })
        }
        return {
            activeId,
            modelValueText,

            panelRef,
            pickerRef,

            isOpen,
            open,
            onChange,

            handlerClear

        }

    }
})
</script>

<style lang="scss">
.v-radio-list-picker {
  position: relative;
}
.v-radio-list-picker-field-active::after {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 94, 220, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
  --tw-border-opacity: 1;
  border-color: rgba(0, 94, 220, var(--tw-border-opacity));
}
.v-radio-list-picker-panel {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-width: 1px;
  margin-top: 0.625rem;
  min-width: 100%;
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: 1;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
</style>