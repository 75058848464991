
import { defineComponent, useCssModule } from "@nuxtjs/composition-api";
// types
import type { SwiperOptions } from "swiper";
import type { PropType } from '@nuxtjs/composition-api'
export default defineComponent({
  name: "HomeBanner",
  props: {
    banners: {
      type: Array as PropType<
        Array<{
          jump_type: string;
          need_login: string;
          path: string;
          path_url: string;
          target: string;
          url: string;
          name: string;
        }>
      >,
      default: () => [],
    },
    height: {
      type: Number,
      default: 520
    },
  },
  setup() {
    const styles = useCssModule();
    const swiperOptions: SwiperOptions = {
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: ".swiper-pagination",
        bulletActiveClass: styles.swiperBulletActive,

        clickable: true,
      },
      autoplay: true,
      // lazy: true,
      loop: true,
    };

    return { swiperOptions };
  },
});
