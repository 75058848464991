import { ProductTypes } from "@/constants/productType";
import {toNumber} from "lodash";

// 产品详情跳转链接
export function productDetailLink(product_type, product_no) {
    return String(product_type) === ProductTypes.CRUISE
        ? `/product/cruise/${product_no}`
        : `/product/vacation/${product_no}`;
}


// 利润 格式化
export function profitFormat(min_profit, max_profit) {
    if (!max_profit) return "";
    if (toNumber(min_profit) === toNumber(max_profit)) return `￥${min_profit}左右`;
    return `￥${min_profit}~￥${max_profit}`;
}

export function buildProductDetailLink({ product_type, product_no, is_tripartite_product }) {
    return String(is_tripartite_product) === '1' ? `/product/automation/${product_no}` : productDetailLink(product_type, product_no);
}
