import { computed, onMounted, reactive, toRaw, useFetch } from "@nuxtjs/composition-api";
import useRequest from "./useRequest";
import { getCruiseFilter } from "~/services/common";
import {createEventHook, createInjectionState} from "@vueuse/shared";
import {merge} from "lodash";

function createDefaultValues() {
    return {
        route: "",
        cruise_route_first: '',
        cruise: "",
        brand: '',

        days: "",
        travel_date: '',
        year: '',

        city: '',
        country: '',
    }
}

const emptyOrValue = (value) => value === '-1' ? '' : value;

const [_useCruiseFilterProvide, useCruiseFilterStore] = createInjectionState((initialValues = {}, filterData, options ) => {
    const state = reactive(merge(initialValues, createDefaultValues()));
    // 航线
    const routes = computed(() => {
        if (!filterData.value.route) {
            return []
        }

        return filterData.value.route.map(item => ({
            id: item.id,
            text: item.name,
            children: [{
                id: '-1',
                text: '全部',
                parent_id: item.id
            }, ...item.list.map(v => ({ id: v.id, text: v.name, parent_id: item.id }))]
        }))
    });
    // 邮轮
    const brands = computed(() => {
        if (!filterData.value.company) {
            return []
        }
        return filterData.value.company.map(c => ({
            id: c.id,
            text: c.name,
            children: [
                {
                    id: '-1',
                    text: '全部',
                    parent_id: c.id
                },
                ...c.list.map(v => ({
                    id: v.id,
                    parent_id: c.id,
                    text: v.name
                }))]
        }))
    });
    // 品牌
    // const brands = computed(() => filterData.value.company);
    // 出游天数
    const days = computed(() => {
        if (!filterData.value.days) {
            return []
        }

        return filterData.value.days.reduce((prev, next) => [...prev, {
            value: `${next.gte}-${next.lte}`,
            text: next.value
        }], [])
    })

    // 出游日期
    const months = computed(() => {
        if (!filterData.value.travel_date) {
            return []
        }
        return filterData.value.travel_date.map(item => ({
            id: item.year,
            text: `${item.year}年`,
            children: [{
                id: '-1',
                text: '全部',
                parent_id: item.year
            }, ...item.month.map((m) => ({
                id: m.id,
                parent_id: item.year,
                text: `${m.name}月`
            }))]
        }))
    })

    const cities = computed(() => {
        if (!filterData.value.port) {
            return []
        }

        return filterData.value.port.map(c => ({
            id: c.country_id,
            text: c.country,
            children: [{
                id: '-1',
                text: '全部',
                parent_id: c.country_id
            }, ...c.list.map(item => ({ id: item.city_id, text: item.city, parent_id: c.country_id }))]
        }))
    })


    // function onChange() {}
    const onChange = (values, action) => {
        Object.assign(state, values)
        const params = toRaw(state);
        if(options && options?.onChange){
            options?.onChange(params, action);
        }
    }

    const onRouteChange = (route, cruise_route_first) => onChange({ route, cruise_route_first }, `route`);
    const onCruiseChange = (cruise, brand) => onChange({ cruise, brand }, `cruise`);

    const onCityChange = (city, country) => {
        onChange({ city, country }, 'city')
    }
    const onDateChange = (travel_date, year) => onChange({ travel_date, year }, `travel_date`)
    const onDayChange = (days) => {
        onChange({ days }, 'days')
    }
    return {
        state,

        routes,
        months,
        cities,
        // cruises,
        brands,
        days,
        // departureCities,


        onChange,

        onRouteChange,
        onCruiseChange,
        onCityChange,
        onDateChange,
        onDayChange
    }
})

function useCruiseFilterProvide(initialValues, options = {}) {
  const changeHook = createEventHook();
  const { state: filterData, execute, isReady } = useRequest(getCruiseFilter, {
    company: [],
    days: [],
    departure_city: [],
    route: [],
    travel_date: []
  }, {
    immediate: !!options?.immediate,
    throwError: true,
    resetOnExecute: false
  })

  _useCruiseFilterProvide(initialValues, filterData, {
    onChange: (values) => {
      console.log('onchange')
      changeHook.trigger(values)
    }
  })

  changeHook.on((values) => {
    fetchFilterData(values);
  })

  changeHook.on((...rest) => options && options.onChange?.(...rest))


  function fetchFilterData(values = {}) {
    const params = Object.assign(values)
    const { days = '' } = params;
    const [gte = '', lte = ''] = days.split('-');
    return execute(0, {
      route: emptyOrValue(params.route),
      cruise_route_first: params.cruise_route_first,
      // company: params.brand,
      cruise: emptyOrValue(params.cruise),
      brand: params.brand,
      city: emptyOrValue(params.city),
      country: emptyOrValue(params.country),
      travel_date: emptyOrValue(params.travel_date),
      year: params.year,
      name: params.name,
      days: {
        gte,
        lte
      },
    })
  }


  return {
    isReady,
    fetchFilterData
  }
}
export {
    useCruiseFilterProvide,
    useCruiseFilterStore
}
